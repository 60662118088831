@import "~bootstrap/scss/bootstrap";
@import "./themes.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  //background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //font-size: calc(10px + 2vmin);
  //color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn:focus,
.btn:active {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: none !important;
}

.Collapsible__trigger {
  align-items: space-between;
  flex-direction: row;
  display: block;
  //flex-direction: column;
  text-align: left;
  background-color: white;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 15px;
  margin-top: 15px;
  color: #444444;
  width: 100%;
  font-size: 20px;
}

.Collapsible__trigger:hover {
  cursor: pointer;
}
//*******************************************

@import "../../themes.scss";

.LoginButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  margin-right: 15px;
  color: $mainColor;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.7;
  font-family: $workingFont;
  //   background-color: $mainColor;
  //   border-radius: 20px;
  //   color: white;
  //   padding: 5px;
  //   padding-left: 15px;
  //   padding-right: 15px;
  //   opacity: 0.8;
}

.LoginButton:hover {
  cursor: pointer;
  opacity: 1;
}
